import "@hotwired/turbo-rails"

// action text
import "@rails/actiontext"
import "trix"
import "trix/dist/trix.css"
import * as ActiveStorage from "@rails/activestorage"

// template
import "../zeomart"
import "owl.carousel"

ActiveStorage.start()

document.addEventListener("turbo:load", function () {
  console.log($.fn.owlCarousel); // Harus mencetak fungsi jika owl.carousel berhasil diimpor
  console.log($.fn.mmenu); // Harus mencetak fungsi jika owl.carousel berhasil diimpor
})
